import type { ContextNodeFragment } from '@aurora/shared-generated/types/graphql-types';
import type {
  ConversationStyle,
  CoreNode,
  MessageConstraints,
  Scalars,
  SupportedNodeType
} from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import {
  allowedConversationStyleMap,
  containerNodeTypeMap
} from '@aurora/shared-types/nodes/types';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { isNodeBoard } from './NodeUtils';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import type { Tenant } from '@aurora/shared-types/tenant';

type HasNodeType = Pick<CoreNode, 'nodeType'>;

/**
 * Get the conversation style for the supplied node, or null if unable
 *
 * @param coreNode the core node
 */
export function getConversationStyle(coreNode: HasNodeType): ConversationStyle | null {
  return isNodeBoard(coreNode) ? coreNode?.conversationStyle : null;
}

/**
 * Get the node type or conversation style for the supplied node.
 *
 * @param coreNode the core node
 */
export function getConversationStyleOrNodeType(coreNode: HasNodeType): string {
  return getConversationStyle(coreNode) ?? coreNode.nodeType;
}

export function getAllowedBoardsInGroupHub(supportedNodeTypes: SupportedNodeType[]): string[] {
  const supportedBoards = supportedNodeTypes?.filter(
    supportedNodeType =>
      !Object.values(NodeType).includes(containerNodeTypeMap[supportedNodeType.nodeType]) &&
      allowedConversationStyleMap[supportedNodeType.nodeType] !== undefined
  );

  return supportedBoards
    ?.filter(supportedNodeType => checkPolicy(supportedNodeType.nodeTypePolicies.canCreate))
    .map(supportedNodeType => allowedConversationStyleMap[supportedNodeType.nodeType]);
}

export const errorResponseFieldNameToFormFieldNameRecord: Record<string, string> = {
  'forum-title': 'FORUM.title',
  'blog-title': 'BLOG.title',
  'tkb-title': 'TKB.title',
  'forum-id': 'FORUM.id',
  'blog-id': 'BLOG.id',
  'tkb-id': 'TKB.id'
};

/**
 * Creates node-scoped constraints, intended for a message query, based on the specified node id and type.
 *
 * @param nodeId the node id
 * @param nodeType the node type
 */
function buildMessageConstraints(nodeId: string, nodeType: string | NodeType): MessageConstraints {
  const constraints: MessageConstraints = {};

  if (!!nodeId && !!nodeType && nodeType !== NodeType.COMMUNITY) {
    const nodeTypeConstraintMap = {
      [NodeType.BOARD]: 'boardId',
      [NodeType.CATEGORY]: 'categoryId',
      [NodeType.GROUPHUB]: 'groupHubId'
    };
    const constraintId = nodeTypeConstraintMap[nodeType];
    constraints[constraintId] = { eq: nodeId };
  }

  return constraints;
}

/**
 * Creates node-scoped constraints, intended for a message query, based on the specified node.
 *
 * @param node - The node to build the constraints for.*
 * @return The message constraints for the specified node.
 */
export function buildMessagesConstraintsForNode(node: ContextNodeFragment): MessageConstraints {
  return buildMessageConstraints(node.id, node.nodeType);
}

/**
 * Creates node and tags scoped constraints, intended for a message query, based on the specified node id and tag texts.
 *
 * @param tenant the tenant
 * @param nodeId the node id
 * @param tagsText the tag filter items
 */
export function buildMessagesConstraintsForNodeById(
  tenant: Tenant,
  nodeId: Scalars['ID']['output'],
  tagsText: string[] = []
): MessageConstraints {
  if (nodeId) {
    const { type } = IdConverter.decodeIdAsParts(tenant, nodeId);
    const constraints = buildMessageConstraints(nodeId, type);
    if (tagsText.length > 0) {
      constraints.tagsText = { in: tagsText };
    }
    return constraints;
  }
  return {};
}
